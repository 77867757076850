<template>
  <b-card no-body>
      <b-row>
        <!-- bio -->
        <b-col cols="12">
          <b-table
            striped
            responsive
            :items="subscriptions"
            class="mb-0"
            sticky-header="true"
            :no-border-collapse="true"
            :fields="tableColumns"
            show-empty
            :empty-text="$t('table.empty')"
          >
            <template #cell(details)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                @change="row.toggleDetails"
              >
                {{ row.detailsShowing ? $t('table.hide') : $t('table.show') }}
              </b-form-checkbox>
            </template>

            <!-- full detail on click -->
            <template #row-details="row">
              <b-card>
                <b-row class="mb-2">
                  <b-col
                    md="4"
                    class="mb-1"
                  >
                    <strong>ID : </strong>{{ row.item.id }}
                  </b-col>
                  <b-col
                    md="4"
                    class="mb-1"
                  >
                    <strong>Payment ID : </strong>{{ row.item.payment_id }}
                  </b-col>
                  <b-col
                    md="4"
                    class="mb-1"
                  >
                    <strong>{{$t('fields.created_at')}} : </strong>{{ new Date(row.item.created_at).toLocaleDateString() }}
                  </b-col>
                  <b-col
                    md="4"
                    class="mb-1"
                  >
                    <strong>{{$t('fields.updated_at')}} : </strong>{{ new Date(row.item.updated_at).toLocaleDateString() }}
                  </b-col>
                  <b-col
                    md="4"
                    class="mb-1"
                  >
                    <strong>{{$t('fields.deleted_at')}} : </strong>{{ row.item.deleted_at ? new Date(row.item.deleted_at).toLocaleDateString() : '' }}
                  </b-col>
                </b-row>

              </b-card>
            </template>

            <template #cell(payment_method_id)="data">

                {{ data.item.payment_method_id }} 
            </template>

            <template #cell(status)="data">
                  <b-badge v-if="data.item.valid_until" :variant="itemIsActive(data.item)?'light-success':'light-danger'">
                    {{ itemIsActive(data.item) ? $t('fields.active') : $t('fields.inactive') }}
                  </b-badge>
              </template>

            <template #cell(valid_from)="data">
                {{ data.item.valid_from ? new Date(data.item.valid_from).toLocaleDateString() :''}} 
              </template>
            <template #cell(valid_until)="data">
                {{ data.item.valid_until ?new Date(data.item.valid_until).toLocaleDateString() :''}}
            </template>


          </b-table>

        </b-col>
        <!--/ bio -->
      </b-row>
  </b-card>
</template>

<script>
import {
  BButton, BRow, BCol, BLink,  BCard, BTable,BBadge, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
BLink,
    BRow,
    BCol,
  BCard, BTable,BBadge,  BButton, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
  },

  props: {
    subscriptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableColumns:[
        { label: this.$t('table.details'), key: 'details', sortable: false },
        { label: this.$t('fields.payment_method_id'), key: 'payment_method_id', sortable: true },
        { label: this.$t('fields.status'), key: 'status' },
        { label: this.$t('fields.valid_from'), key: 'valid_from', sortable: true },
        { label: this.$t('fields.valid_until'), key: 'valid_until', sortable: true },
      ]
    }
  },
  methods: {
    itemIsActive(item){
      if(new Date(item.valid_until) > new Date() ){
        return true
      } else {
        return false
      }
    },
    
  },
}
</script>

<style lang="scss">
</style>

<template>
  <b-card>

    <!-- form -->
    <b-form >
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <b-form-input
              :value="optionsLocal.email"
              name="email"
              placeholder="Email"
              disabled
            />
          </b-form-group>
        </b-col>
        <!-- alert -->
        <b-col
          cols="6"
          class="mt-75"
        >
          <b-alert
            show
            variant="warning"
            class="mb-50"
          >
            <h4 class="alert-heading">
              {{$t('settings.email_alert')}}
            </h4>
            
          </b-alert>
        </b-col>
        <!--/ alert -->
      </b-row>
      <b-row>
        <b-col sm="6">
          <b-form-group
            :label="$t('fields.name')"
            label-for="account-name"
          >
            <b-form-input
              v-model="optionsLocal.fullName"
              name="name"
              :placeholder="$t('fields.name')"
            />
          </b-form-group>
        </b-col>
        
        <b-col sm="6">
          <b-form-group
            :label="$t('fields.lang')"
            label-for="account-lang"
          >
          <v-select
            v-model="optionsLocal.lang"
            :options="lang_options"
          />
          </b-form-group>
        </b-col> 

        

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="save()"
          >
            {{ $t('forms.save_changes') }}
          </b-button>

        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,

    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
    lang_options: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
    }
  },
  methods: {
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    validate(){
      if(this.optionsLocal.fullName == ''){
          this.makeToast('danger', this.$t('Error'), 'Complete Name');
        return false
      }
      
      if(this.optionsLocal.lang == ''){
          this.makeToast('danger', this.$t('Error'), 'Select a Language');
        return false
      }
      return true
    },
    async save(){
      if(this.validate()){
        let data = {
          context: 'general',
          name: this.optionsLocal.fullName,
          lang: this.optionsLocal.lang,
        }
        await this.$http.post('editUser', data).then( response => {
            console.log(response.data)
            if(response.data.status == 200){
              this.$i18n.locale = response.data.lang_iso_code
              this.$store.commit('user/changeLang', response.data.lang_iso_code)

              this.makeToast('success', this.$t('Success'), this.$t('forms.update_success'));
            } else {
              this.makeToast('danger', this.$t('Error'), response.data.msg);
            }
          }) 
      }
    }
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
 
}
</script>
